import React from "react";

import useMarketMoversRanking from "../hooks/useMarketMoversRanking";
import useFetchCompaniesList from "../hooks/useFetchCompaniesList";
import MarketsList from "./MarketsList";
import Tabbed from "./Tabbed";
import { Grid, Cell, Spacing } from "./Layout";
import Text from "./Text";

const MarketSplit = ({ ranking }) => {
  return (
    <Grid>
      <Cell size={6}>
        <MarketsList title="Risers" listings={ranking.top} />
      </Cell>
      <Cell size={6}>
        <MarketsList title="Fallers" order="asc" listings={ranking.bottom} />
      </Cell>
    </Grid>
  );
};

export const MarketWidget = ({ ranking }) => {
  return (
    <Tabbed slim>
      <MarketsList title="Risers" listings={ranking.top} />
      <MarketsList title="Fallers" order="asc" listings={ranking.bottom} />
    </Tabbed>
  );
};

export default function MarketsWinnersLosers({ splitView = false, index }) {
  const ranking = useMarketMoversRanking({ index });
  useFetchCompaniesList([...ranking.top, ...ranking.bottom])

  return splitView ? (
    <MarketSplit ranking={ranking} />
  ) : (
    <MarketWidget ranking={ranking} />
  );
}
