import React from "react";
import { Grid, Cell, Spacing } from "components/Layout";
import Article from "features/articles/components/Article";
import { useHomepageArticles } from "features/articles/queries";
import RecentArticles from "./RecentArticles";
import { FeaturedMedia } from "features/reports";

import { useAds } from "features/adsense/queries";
import { AdComponent } from "features/adsense/components";


export default function FeaturedBlogs({ singleColumn = false }) {
  const { data } = useHomepageArticles();
  const { data: ads, loading } = useAds();

  const homeBoxAd = ads && ads.find((unit) => unit.slot === "home-box");

  const articles = data || new Array(4);//.pages[0];

  return (
    <div>
      <Spacing top={1}>
        <Grid gutterSize={2}>
          <Cell lgCol={singleColumn ? 12 : 6}>
            <Spacing bottom={2}>
              <Article article={articles[0]} />
            </Spacing>
            <Spacing bottom={2}>
              <RecentArticles />
            </Spacing>
          </Cell>
          <Cell lgCol={singleColumn ? 12 : 6}>
            <Grid>
              <Cell lgCol={6} mdCol={6} smCol={12}>
                <Spacing bottom={2}>
                  <Article article={articles[1]} />
                </Spacing>
              </Cell>
              <Cell lgCol={6} mdCol={6} smCol={12}>
                <Spacing bottom={2}>
                  <Article article={articles[2]} />
                </Spacing>
              </Cell>
            </Grid>
            <Grid>
              <Cell lgCol={6} mdCol={6} smCol={12}>
                <Spacing bottom={2}>
                  <Article article={articles[3]} />
                </Spacing>
              </Cell>
              <Cell lgCol={6} mdCol={6} smCol={12}>
                <Spacing bottom={2}>
                  <Article article={articles[4]} />
                </Spacing>
              </Cell>
            </Grid>
            {homeBoxAd && (
              <Spacing vertical={1}>
                <AdComponent slot="home-box" />
              </Spacing>
            )}

              <FeaturedMedia reportName="homepageFeaturedMedia" />
          </Cell>
        </Grid>
      </Spacing>
    </div>
  );
}
