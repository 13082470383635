import React from "react";
import styled from "styled-components";
import CompanyPageLink from "./CompanyPageLink";
import { useSelector } from "react-redux";

import * as Selectors from "../redux/selectors";
import useQuote from "../hooks/useQuote";
import { Grid, Cell } from "./Layout";
import Text from "./Text";
import CompanyLogo from "./Company/CompanyLogo";
import { formatNumber } from "../utils";

const Wrapper = styled.div`
  height: 62px;
  padding: 6px 10px;
`;

const Link = styled(CompanyPageLink)`
  display: block;
`;
const Row = styled.div`
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 50px;
`;

const Company = styled.div`
  width: 50%;
`;

const Price = styled.span`
  flex-basis: 27%;
  padding-left: 5px;
  text-align: right;
`;

const PriceChange = styled(Text)`
  flex-basis: 23%;
`;

export default function MarketsRow({ listingId }) {
  const snapshot = useQuote({ listingId });
  const listing = useSelector((state) =>
    Selectors.selectListingById(state, listingId)
  );
  const company = useSelector((state) =>
    Selectors.selectCompanyByListingId(state, listingId)
  );

  return (
    <Wrapper>
      {listing && (
        <Link listing={listing}>
          <Row>
            <Company>
              {company && (
                <Grid middle gutterSize={0.5}>
                  <Cell fit>
                    <CompanyLogo companyId={company.id} size={50} />
                  </Cell>

                  <Cell fill>
                    <Text bold clip>
                      {listing.listingShortTicker}
                    </Text>
                    <Text size={0.78} clip>
                      {company.companyName}
                    </Text>
                  </Cell>
                </Grid>
              )}
            </Company>
            {snapshot && (
              <>
                <PriceChange
                  bold
                  right
                  negative={snapshot.change < 0}
                  positive={snapshot.change > 0}
                >
                  {formatNumber(snapshot.pctChange, 2)}%
                </PriceChange>
                <Price>
                  <div>
                    <Text as="small" size={0.75} light>
                      {snapshot.curCode}{" "}
                    </Text>
                    <Text as="span" size={0.8} bold>
                      {formatNumber(snapshot.mid, snapshot.decimalPlaces)}
                    </Text>
                  </div>
                  <Text size={0.8}>
                    {snapshot.change > 0 && "+"}
                    {formatNumber(snapshot.change, 2)}
                  </Text>
                </Price>
              </>
            )}
          </Row>
        </Link>
      )}
    </Wrapper>
  );
}
