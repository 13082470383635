import React from "react";
import { useUserArticles } from "features/articles/queries";

import { Grid, Cell, Border, Spacing } from "components/Layout";
import Text from "components/Text";

import Article from "features/articles/components/Article";

const ArticleBlock = ({ author, label, ...props }) => {
  const { data: articles } = useUserArticles( author );

  return <Spacing all={1}>
    <Text as="h2" bold size={1.2}>{label}</Text>
    <Spacing top={1}>
      <Grid>
        <Cell mdCol={6} lgCol={3}>
          {props.children}
        </Cell>
        {articles && articles.pages[0].slice(0,3).map( a => <Cell  mdCol={6} lgCol={3}><Spacing bottom={0.5}><Article article={a} /></Spacing></Cell>)}
      </Grid>
  </Spacing>
  </Spacing>
}

export default ArticleBlock;
