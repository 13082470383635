import React from "react";
import styled from "styled-components";
import logo from "./finalto.png";
import Text from "components/Text";
import Link from "components/Link";

const Wrapper = styled.div`

  background-color: ${ props => props.theme.color.dark };
  padding: 1rem;
  height: 100%;

  img {
  display:block;
  max-width: 80%;
  margin: 0 auto 1rem;
  }
  
`;

const Box = () => {

  return <Wrapper>
    <Link href="/authors/finalto/"><img src={logo} alt="Finalto" /></Link>
    <Text white size={0.9}>
      Market commentator Neil Wilson and top guests explore the big macro themes affecting investors, economic imbalances, giant geopolitical trends, tail risks and tremors; and everywhere risks are not fully priced into markets.
    </Text>
  </Wrapper>
  
}

export default Box;
