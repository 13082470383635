import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { useQuery } from "react-query";
import { Link } from "components/Utils";
import AltHeader from "components/AltHeader";
import UL from "components/UL";

const Box = styled.li`
  ${Link} {
  padding:8px;
  display:block;
  }
  strong {
  font-size:1rem;
  display:block;
  line-height:1.1;
  }
  small {
  color:#888;
  line-height:1;
  }
`

export function MostLikedNewsRow({ title, storyId, timestamp }) {
  const date = new Date(timestamp * 1000);
  return (
    <Box>
      <Link href={`/rns/announcement/${storyId}/`}>
        <small>{format(date, "do MMMM yyyy")} {format(date, "HH:mm")}</small>
        <strong>{title}</strong>
      </Link>
    </Box>
  );
}

export default function MostLikedNews() {
  const { data } = useQuery(
    "popularNews",
    async () => {
      const res = await fetch(`https://api.voxmarkets.co.uk/v4/rnsStatistics?report=today`);
      return res.json();
    },
    {
      staleTime: 300000
    }
  );

  
  
  if (!data) {
    return null;
  }

  return (
    <section>
      <AltHeader white size={1} bold as="h2">
        Most Read RNS
      </AltHeader>
      <UL zebra>
        {data.slice(0,10).map(({ storyId, storyHeadline, timestampSeconds }) => (
          <MostLikedNewsRow
            key={storyId}
            title={storyHeadline}
            timestamp={timestampSeconds}
            storyId={storyId}
          />
        ))
        }
      </UL>
    </section>
  );
}
