import { useQuery, useInfiniteQuery, useQueryClient } from "react-query";
import { v2API } from "@voxmarkets/api-sdk";

export function bannerQuery(slot) {
  return {
    queryKey: slot,
    queryFn: () => Promise.resolve([{
      slot,
      image: 'https://s3-eu-west-1.amazonaws.com/vox.assets.public/site-assets/northbridge-rsvp.png',
      link: 'mailto:northbridge@voxmarkets.co.uk',
      timestampFrom: new Date("2022-03-08T00:00:00.000Z"),
      timestampTo: new Date("2022-03-10T14:30:00.000Z")
    }].filter( banner => banner.timestampFrom <= Date.now() && Date.now() < banner.timestampTo ) ),
    staleTime: 300000, // 5min
  };
}

export function useBanner(slot) {
  return useQuery(bannerQuery(slot));
}
