import MediaAsset from "features/media-assets/components/MediaAsset";
import { Spacing } from "components/Layout";

import useReport from "../hooks/useReport";

export default function FeaturedMedia({ reportName }) {
  const { data: report } = useReport(reportName);

  if (!report || !report.isPublished || !report.mediaAssetId) {
    return null;
  }

  const { mediaAssetId } = report;

  return (
    <section>
      <Spacing vertical={0.75}>
        <MediaAsset mediaAssetId={mediaAssetId} link={`/?mediaAssetId=`} />
      </Spacing>
    </section>
  );
}
