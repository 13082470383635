import React from "react";
import { useBanner } from "features/banners/queries";

import Image from "components/Image";

export default function MainBanner({ slot, ...props }) {
  const { data: banners } = useBanner(slot);

  if (!banners || banners.length === 0) {
    return null
  }

  if(banners.length === 1) {
    const [banner] = banners;
    return <div {...props}>
      <a href={banner.link} target="_blank">
        <img src={banner.image} />
      </a>
    </div>
  }

  
}
