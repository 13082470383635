import React, {useEffect} from "react";
import styled from "styled-components";
import { map, pipe, get, filter } from "lodash/fp";

import { Actions } from "@voxmarkets/vox-reducers";

import AddToWatchlist from "containers/CompanyFollowButton";
import UL from "components/UL";
import AltHeader from "components/AltHeader";

import MarketsRow from "components/MarketsRow";


import { useSelector, useDispatch } from "react-redux";

import useFetchCompaniesList from "../../../hooks/useFetchCompaniesList";
import useReport from "../hooks/useReport";

const RowTitle = styled.div`
  display:flex;
  align-items: center;
  padding-right: 8px;
  > :first-child {
  flex-grow:1;
  }
`;

function MostFollowedRow({ item }) {
  return (
    <RowTitle>
      <MarketsRow listingId={item.listing.id} />
      <AddToWatchlist listingId={item.listing.id} />
    </RowTitle>
  );
}

export default function MostFollowed() {
  const { data: report } = useReport("companyFollowerDeltaReport");
  const dispatch = useDispatch();
  useFetchCompaniesList(report?report.items.map( i => i.listing.id):[]);

  useEffect(() => {
    const listingIds = report?report.items.map(i=>i.listing.id):[];
    if (listingIds.length>0) {
      dispatch(Actions.quotes.fetchQuotes(listingIds));
    }
  }, [report]);
  if (!report) {
    return null;
  }
  

  return (
    <section>
      <AltHeader white size={1} bold as="h2">
        Most Followed Companies
      </AltHeader>
      <UL zebra>
        {pipe(
          get("items"),
          filter("listing"),
          map((item) => <li key={item.listing.id}><MostFollowedRow item={item} /></li>)
        )(report)}
      </UL>
    </section>
  );
}
