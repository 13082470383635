import React from "react";
import styled, { css } from "styled-components";

const Nav = styled.nav`
  display: flex;
  align-items: stretch;
`;

const Tab = styled.span`
  background-color: ${props => (props.active ? props.theme.color.dark : "")};
  color: ${props => (props.active ? "#fff" : props.theme.color.dark)};
  padding: 0.5rem;
  flex-grow: 1;
  font-weight:bold;
  line-height:1.2;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  &:hover {
  background-color: ${props =>
  props.active ? props.theme.color.dark : "#e0e7ec;"};
  }
`;

const Wrapper = styled.div`
  font-family: ${props => props.theme.font.default};

  ${props =>
  props.underline &&
  css`
${Nav} {
        border-bottom: 2px solid ${props => props.theme.color.dark};
      }
    `};

  ${props =>
    props.slim &&
    css`
      ${Nav} {
        border-bottom: 1px solid ${props => props.theme.color.dark};
      }
    `};
`;

class Tabbed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabIndex: this.props.activeTab || 0
    };
  }

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.activeTab !== undefined &&
      nextProps.activeTab !== this.state.activeTabIndex
    ) {
      this.setState({
        activeTabIndex: nextProps.activeTab
      });
    }
  };

  componentDidMount = () => {
    this.onChange(this.state.activeTabIndex);
  };

  setActive = index => {
    if (index !== this.state.activeTabIndex) {
      this.setState({
        activeTabIndex: index
      });

      this.onChange(index);
    }
  };

  onChange = id => {
    let title = "";
    if (
      this.props.children &&
      this.props.children[id] &&
      this.props.children[id].props
    ) {
      title = this.props.children[id].props.title;
    }

    this.props.onChange && this.props.onChange(id, title);
  };

  render() {
    return (
      <Wrapper underline={this.props.underline} slim={this.props.slim}>
        <Nav>
          {this.props.children.map((tab, idx) => {
            if (!tab.props) return null;
            const { title, route, href, ...props } = tab.props;
            const tabProps = {
              key: href || route || title || idx,
              active: idx === this.state.activeTabIndex,
              href,
              ...props
            };
            return href ? (
              <Tab as="a" {...tabProps}> {title} </Tab>
            ) : (
              <Tab {...tabProps} onClick={e => this.setActive(idx)}>
                {title}
              </Tab>
            );
          })}
        </Nav>
        <div>
          {this.props.children.map((tab, idx) => {
            if (idx === this.state.activeTabIndex) {
              if (tab.props.onTabActive) {
                tab.props.onTabActive();
              }
              return tab;
            }
          })}
        </div>
      </Wrapper>
    );
  }
}

export default Tabbed;
