import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Actions } from "@voxmarkets/vox-reducers";

/**
 * Fetch company data with list of listings ids
 *
 * @param {Array} listingIds
 */
export default function useFetchCompaniesList(listingIds) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(listingIds)) {
      const fetchListings = (listingIds) =>
        dispatch(Actions.advCompanies.listings.getByIds(listingIds));
      const fetchIsues = (issueIds) =>
        dispatch(Actions.advCompanies.issues.getByIds(issueIds));
      const fetchCompanies = (companyIds) =>
        dispatch(Actions.advCompanies.companies.getByIds(companyIds));

      fetchListings(listingIds)
        .then((data) => data.result.map((listing) => listing.issueId))
        .then(fetchIsues)
        .then((data) => data.result.map((issue) => issue.issueCompanyId))
        .then(fetchCompanies);
    }
  }, [dispatch, listingIds]);
}
