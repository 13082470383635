import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MarketsRow from "./MarketsRow";
import UL from "./UL";

const Wrapper = styled.div`
  min-height: 620px;
`;

export default function MarketsList({ listings, order = "desc" }) {
  const quotes = useSelector(state => state.quotes);
  const sortedListings = listings.sort((a, b) => {
    if (quotes.hasOwnProperty(a) && quotes.hasOwnProperty(b)) {
      return order === "desc"
        ? quotes[b].pctChange - quotes[a].pctChange
        : quotes[a].pctChange - quotes[b].pctChange;
    }
    return 0;
  });

  return (
    <Wrapper>
      <UL zebra>
        {sortedListings.map(listingId => (
          <li key={listingId}>
            <MarketsRow listingId={listingId} />
          </li>
        ))}
      </UL>
    </Wrapper>
  );
}
