import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectQuoteByListingId } from "../redux/selectors";

export default function useQuote({ listingId }) {
  const snapshot = useSelector((state) =>
    selectQuoteByListingId(state, listingId)
  );

  return snapshot;
}
