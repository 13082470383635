import React from "react";
import { marketIndices } from "../constants";
import { BoxShadow2 } from "./Utils";
import { Spacing } from "./Layout";
import MarketWinnersLosers from "./MarketWinnersLosers";
import MarketsSelect from "./MarketsSelect";

export default function MarketMovers() {
  const [selectedCollection, setSelectedCollection] = React.useState(
    "5993224227bb7e00119e87c0"
  );

  return (
    
      <MarketWinnersLosers index="5993224227bb7e00119e87c0" />
    
  );
}
