import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { get, isEmpty } from "lodash";
import { Actions } from "@voxmarkets/vox-reducers";

import { getMarketMovers } from "../api";

export default function useMarketMoversRanking({ index }) {
  const dispatch = useDispatch();

  const { data } = useQuery(
    ["marketMovers", index],
    () => getMarketMovers(index),
    { refetchInterval: 7000 }
  );

  const ranking = get(data, "[0].rankings", { top: [], bottom: [] });

  useEffect(() => {
    const listingIds = [...ranking.top, ...ranking.bottom];
    if (!isEmpty(listingIds)) {
      dispatch(Actions.quotes.fetchQuotes(listingIds));
    }
  }, [dispatch, ranking, index]);

  return ranking;
}
